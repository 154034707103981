import plugin_vue3_A0OWXRrUgq from "/home/mdp0811/office_work/95dot/95dot-com-frontend/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import revive_payload_client_4sVQNw7RlN from "/home/mdp0811/office_work/95dot/95dot-com-frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import components_plugin_KR1HBZs4kY from "/home/mdp0811/office_work/95dot/95dot-com-frontend/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/home/mdp0811/office_work/95dot/95dot-com-frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/mdp0811/office_work/95dot/95dot-com-frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_5tzzN0oIVL from "/home/mdp0811/office_work/95dot/95dot-com-frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import motion_fmZWau4t0U from "/home/mdp0811/office_work/95dot/95dot-com-frontend/node_modules/@vueuse/motion/dist/runtime/templates/motion.mjs";
import chunk_reload_client_UciE0i6zes from "/home/mdp0811/office_work/95dot/95dot-com-frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_1UohGbtF8v from "/home/mdp0811/office_work/95dot/95dot-com-frontend/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import axios_sVCuMR6hEC from "/home/mdp0811/office_work/95dot/95dot-com-frontend/plugins/axios.js";
import fontawesome_klhsrycjcK from "/home/mdp0811/office_work/95dot/95dot-com-frontend/plugins/fontawesome.js";
export default [
  plugin_vue3_A0OWXRrUgq,
  revive_payload_client_4sVQNw7RlN,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  prefetch_client_5tzzN0oIVL,
  motion_fmZWau4t0U,
  chunk_reload_client_UciE0i6zes,
  plugin_1UohGbtF8v,
  axios_sVCuMR6hEC,
  fontawesome_klhsrycjcK
]