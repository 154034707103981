import { default as _91name_936uEmyarKK7Meta } from "/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/[name].vue?macro=true";
import { default as aboutustxCyY3i1o8Meta } from "/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/aboutus.vue?macro=true";
import { default as index9xNfCo4kuAMeta } from "/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/admin/account/index.vue?macro=true";
import { default as refer_45earnQNxKRQMXxhMeta } from "/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/admin/account/refer-earn.vue?macro=true";
import { default as accountIrkWflpTsGMeta } from "/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/admin/account.vue?macro=true";
import { default as analyticsiTD7oft8UkMeta } from "/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/admin/analytics.vue?macro=true";
import { default as indexZ6PVMntmffMeta } from "/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/admin/index.vue?macro=true";
import { default as moresagh9keBbTMeta } from "/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/admin/more.vue?macro=true";
import { default as indexU9511Egv0MMeta } from "/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/admin/nfc/index.vue?macro=true";
import { default as ordersHnMpQO0EqjMeta } from "/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/admin/nfc/orders.vue?macro=true";
import { default as nfciQOwDTiyPRMeta } from "/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/admin/nfc.vue?macro=true";
import { default as previewgNHEO3nanSMeta } from "/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/admin/preview.vue?macro=true";
import { default as _91name_930rqCosF7NhMeta } from "/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/admin/space/[name].vue?macro=true";
import { default as indexwbSsvHDBohMeta } from "/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/admin/space/index.vue?macro=true";
import { default as spaceVQaubAOrKsMeta } from "/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/admin/space.vue?macro=true";
import { default as adminEvk714UDuUMeta } from "/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/admin.vue?macro=true";
import { default as affiliateuZWxFbO623Meta } from "/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/affiliate.vue?macro=true";
import { default as cancellationandrefundpolicyZL7JCmaeVHMeta } from "/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/cancellationandrefundpolicy.vue?macro=true";
import { default as _91id_93P7X5AV1BsWMeta } from "/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/card/[id].vue?macro=true";
import { default as checkout_45successLqTPWxKjCIMeta } from "/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/checkout-success.vue?macro=true";
import { default as contact_45usj8mDlmKywnMeta } from "/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/contact-us.vue?macro=true";
import { default as cookiepolicyYy2tJjjD1aMeta } from "/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/cookiepolicy.vue?macro=true";
import { default as faqsCYewoIxe09Meta } from "/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/faqs.vue?macro=true";
import { default as forgot_45passwordIyUVzZ8S9zMeta } from "/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/index/forgot-password.vue?macro=true";
import { default as indexRytedw4eF9Meta } from "/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/index/index.vue?macro=true";
import { default as login3ERfpisPLkMeta } from "/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/index/login.vue?macro=true";
import { default as registerdjVRwlqwbBMeta } from "/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/index/register.vue?macro=true";
import { default as verify_45emailObaTddw4LsMeta } from "/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/index/verify-email.vue?macro=true";
import { default as verifyZYLD0Hpz1xMeta } from "/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/index/verify.vue?macro=true";
import { default as indexKYgMfNkA0KMeta } from "/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/index.vue?macro=true";
import { default as jobsYNX41RUbPIMeta } from "/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/jobs.vue?macro=true";
import { default as _91orderv2Id_93cu9FUtdLLeMeta } from "/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/order/[orderv2Id].vue?macro=true";
import { default as cart4cMe1ncdGwMeta } from "/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/order/cart.vue?macro=true";
import { default as checkout2azuJbKbivMeta } from "/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/order/checkout.vue?macro=true";
import { default as index12juo0NYadMeta } from "/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/order/index.vue?macro=true";
import { default as _91token_93Y6DieYIDwmMeta } from "/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/password-reset/[token].vue?macro=true";
import { default as privacypolicyEwGYrEBY3RMeta } from "/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/privacypolicy.vue?macro=true";
import { default as productssALdo5NBUBMeta } from "/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/products.vue?macro=true";
import { default as shippingandorderpolicyccwLY0Lay9Meta } from "/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/shippingandorderpolicy.vue?macro=true";
import { default as termsofusejIke6rr4JtMeta } from "/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/termsofuse.vue?macro=true";
import { default as testPayment51RjN45jgUMeta } from "/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/testPayment.vue?macro=true";
import { default as _91id_93aLQDSPJUrLMeta } from "/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/user/[id].vue?macro=true";
import { default as exist_45loginL6IqdaCJV2Meta } from "/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/v2/exist-login.vue?macro=true";
import { default as loginbraAuB9i6SMeta } from "/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/v2/login.vue?macro=true";
import { default as setup_45profileimKmgy7lSxMeta } from "/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/v2/setup-profile.vue?macro=true";
export default [
  {
    name: _91name_936uEmyarKK7Meta?.name ?? "name",
    path: _91name_936uEmyarKK7Meta?.path ?? "/:name()",
    meta: _91name_936uEmyarKK7Meta || {},
    alias: _91name_936uEmyarKK7Meta?.alias || [],
    redirect: _91name_936uEmyarKK7Meta?.redirect || undefined,
    component: () => import("/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/[name].vue").then(m => m.default || m)
  },
  {
    name: aboutustxCyY3i1o8Meta?.name ?? "aboutus",
    path: aboutustxCyY3i1o8Meta?.path ?? "/aboutus",
    meta: aboutustxCyY3i1o8Meta || {},
    alias: aboutustxCyY3i1o8Meta?.alias || [],
    redirect: aboutustxCyY3i1o8Meta?.redirect || undefined,
    component: () => import("/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/aboutus.vue").then(m => m.default || m)
  },
  {
    path: adminEvk714UDuUMeta?.path ?? "/admin",
    children: [
  {
    path: accountIrkWflpTsGMeta?.path ?? "account",
    children: [
  {
    name: index9xNfCo4kuAMeta?.name ?? "admin-account",
    path: index9xNfCo4kuAMeta?.path ?? "",
    meta: index9xNfCo4kuAMeta || {},
    alias: index9xNfCo4kuAMeta?.alias || [],
    redirect: index9xNfCo4kuAMeta?.redirect || undefined,
    component: () => import("/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/admin/account/index.vue").then(m => m.default || m)
  },
  {
    name: refer_45earnQNxKRQMXxhMeta?.name ?? "admin-account-refer-earn",
    path: refer_45earnQNxKRQMXxhMeta?.path ?? "refer-earn",
    meta: refer_45earnQNxKRQMXxhMeta || {},
    alias: refer_45earnQNxKRQMXxhMeta?.alias || [],
    redirect: refer_45earnQNxKRQMXxhMeta?.redirect || undefined,
    component: () => import("/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/admin/account/refer-earn.vue").then(m => m.default || m)
  }
],
    name: accountIrkWflpTsGMeta?.name ?? undefined,
    meta: accountIrkWflpTsGMeta || {},
    alias: accountIrkWflpTsGMeta?.alias || [],
    redirect: accountIrkWflpTsGMeta?.redirect || undefined,
    component: () => import("/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/admin/account.vue").then(m => m.default || m)
  },
  {
    name: analyticsiTD7oft8UkMeta?.name ?? "admin-analytics",
    path: analyticsiTD7oft8UkMeta?.path ?? "analytics",
    meta: analyticsiTD7oft8UkMeta || {},
    alias: analyticsiTD7oft8UkMeta?.alias || [],
    redirect: analyticsiTD7oft8UkMeta?.redirect || undefined,
    component: () => import("/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/admin/analytics.vue").then(m => m.default || m)
  },
  {
    name: indexZ6PVMntmffMeta?.name ?? "admin",
    path: indexZ6PVMntmffMeta?.path ?? "",
    meta: indexZ6PVMntmffMeta || {},
    alias: indexZ6PVMntmffMeta?.alias || [],
    redirect: indexZ6PVMntmffMeta?.redirect || undefined,
    component: () => import("/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: moresagh9keBbTMeta?.name ?? "admin-more",
    path: moresagh9keBbTMeta?.path ?? "more",
    meta: moresagh9keBbTMeta || {},
    alias: moresagh9keBbTMeta?.alias || [],
    redirect: moresagh9keBbTMeta?.redirect || undefined,
    component: () => import("/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/admin/more.vue").then(m => m.default || m)
  },
  {
    path: nfciQOwDTiyPRMeta?.path ?? "nfc",
    children: [
  {
    name: indexU9511Egv0MMeta?.name ?? "admin-nfc",
    path: indexU9511Egv0MMeta?.path ?? "",
    meta: indexU9511Egv0MMeta || {},
    alias: indexU9511Egv0MMeta?.alias || [],
    redirect: indexU9511Egv0MMeta?.redirect || undefined,
    component: () => import("/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/admin/nfc/index.vue").then(m => m.default || m)
  },
  {
    name: ordersHnMpQO0EqjMeta?.name ?? "admin-nfc-orders",
    path: ordersHnMpQO0EqjMeta?.path ?? "orders",
    meta: ordersHnMpQO0EqjMeta || {},
    alias: ordersHnMpQO0EqjMeta?.alias || [],
    redirect: ordersHnMpQO0EqjMeta?.redirect || undefined,
    component: () => import("/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/admin/nfc/orders.vue").then(m => m.default || m)
  }
],
    name: nfciQOwDTiyPRMeta?.name ?? undefined,
    meta: nfciQOwDTiyPRMeta || {},
    alias: nfciQOwDTiyPRMeta?.alias || [],
    redirect: nfciQOwDTiyPRMeta?.redirect || undefined,
    component: () => import("/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/admin/nfc.vue").then(m => m.default || m)
  },
  {
    name: previewgNHEO3nanSMeta?.name ?? "admin-preview",
    path: previewgNHEO3nanSMeta?.path ?? "preview",
    meta: previewgNHEO3nanSMeta || {},
    alias: previewgNHEO3nanSMeta?.alias || [],
    redirect: previewgNHEO3nanSMeta?.redirect || undefined,
    component: () => import("/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/admin/preview.vue").then(m => m.default || m)
  },
  {
    path: spaceVQaubAOrKsMeta?.path ?? "space",
    children: [
  {
    name: _91name_930rqCosF7NhMeta?.name ?? "admin-space-name",
    path: _91name_930rqCosF7NhMeta?.path ?? ":name()",
    meta: _91name_930rqCosF7NhMeta || {},
    alias: _91name_930rqCosF7NhMeta?.alias || [],
    redirect: _91name_930rqCosF7NhMeta?.redirect || undefined,
    component: () => import("/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/admin/space/[name].vue").then(m => m.default || m)
  },
  {
    name: indexwbSsvHDBohMeta?.name ?? "admin-space",
    path: indexwbSsvHDBohMeta?.path ?? "",
    meta: indexwbSsvHDBohMeta || {},
    alias: indexwbSsvHDBohMeta?.alias || [],
    redirect: indexwbSsvHDBohMeta?.redirect || undefined,
    component: () => import("/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/admin/space/index.vue").then(m => m.default || m)
  }
],
    name: spaceVQaubAOrKsMeta?.name ?? undefined,
    meta: spaceVQaubAOrKsMeta || {},
    alias: spaceVQaubAOrKsMeta?.alias || [],
    redirect: spaceVQaubAOrKsMeta?.redirect || undefined,
    component: () => import("/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/admin/space.vue").then(m => m.default || m)
  }
],
    name: adminEvk714UDuUMeta?.name ?? undefined,
    meta: adminEvk714UDuUMeta || {},
    alias: adminEvk714UDuUMeta?.alias || [],
    redirect: adminEvk714UDuUMeta?.redirect || undefined,
    component: () => import("/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/admin.vue").then(m => m.default || m)
  },
  {
    name: affiliateuZWxFbO623Meta?.name ?? "affiliate",
    path: affiliateuZWxFbO623Meta?.path ?? "/affiliate",
    meta: affiliateuZWxFbO623Meta || {},
    alias: affiliateuZWxFbO623Meta?.alias || [],
    redirect: affiliateuZWxFbO623Meta?.redirect || undefined,
    component: () => import("/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/affiliate.vue").then(m => m.default || m)
  },
  {
    name: cancellationandrefundpolicyZL7JCmaeVHMeta?.name ?? "cancellationandrefundpolicy",
    path: cancellationandrefundpolicyZL7JCmaeVHMeta?.path ?? "/cancellationandrefundpolicy",
    meta: cancellationandrefundpolicyZL7JCmaeVHMeta || {},
    alias: cancellationandrefundpolicyZL7JCmaeVHMeta?.alias || [],
    redirect: cancellationandrefundpolicyZL7JCmaeVHMeta?.redirect || undefined,
    component: () => import("/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/cancellationandrefundpolicy.vue").then(m => m.default || m)
  },
  {
    name: _91id_93P7X5AV1BsWMeta?.name ?? "card-id",
    path: _91id_93P7X5AV1BsWMeta?.path ?? "/card/:id()",
    meta: _91id_93P7X5AV1BsWMeta || {},
    alias: _91id_93P7X5AV1BsWMeta?.alias || [],
    redirect: _91id_93P7X5AV1BsWMeta?.redirect || undefined,
    component: () => import("/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/card/[id].vue").then(m => m.default || m)
  },
  {
    name: checkout_45successLqTPWxKjCIMeta?.name ?? "checkout-success",
    path: checkout_45successLqTPWxKjCIMeta?.path ?? "/checkout-success",
    meta: checkout_45successLqTPWxKjCIMeta || {},
    alias: checkout_45successLqTPWxKjCIMeta?.alias || [],
    redirect: checkout_45successLqTPWxKjCIMeta?.redirect || undefined,
    component: () => import("/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/checkout-success.vue").then(m => m.default || m)
  },
  {
    name: contact_45usj8mDlmKywnMeta?.name ?? "contact-us",
    path: contact_45usj8mDlmKywnMeta?.path ?? "/contact-us",
    meta: contact_45usj8mDlmKywnMeta || {},
    alias: contact_45usj8mDlmKywnMeta?.alias || [],
    redirect: contact_45usj8mDlmKywnMeta?.redirect || undefined,
    component: () => import("/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: cookiepolicyYy2tJjjD1aMeta?.name ?? "cookiepolicy",
    path: cookiepolicyYy2tJjjD1aMeta?.path ?? "/cookiepolicy",
    meta: cookiepolicyYy2tJjjD1aMeta || {},
    alias: cookiepolicyYy2tJjjD1aMeta?.alias || [],
    redirect: cookiepolicyYy2tJjjD1aMeta?.redirect || undefined,
    component: () => import("/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/cookiepolicy.vue").then(m => m.default || m)
  },
  {
    name: faqsCYewoIxe09Meta?.name ?? "faqs",
    path: faqsCYewoIxe09Meta?.path ?? "/faqs",
    meta: faqsCYewoIxe09Meta || {},
    alias: faqsCYewoIxe09Meta?.alias || [],
    redirect: faqsCYewoIxe09Meta?.redirect || undefined,
    component: () => import("/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/faqs.vue").then(m => m.default || m)
  },
  {
    path: indexKYgMfNkA0KMeta?.path ?? "/",
    children: [
  {
    name: forgot_45passwordIyUVzZ8S9zMeta?.name ?? "index-forgot-password",
    path: forgot_45passwordIyUVzZ8S9zMeta?.path ?? "forgot-password",
    meta: forgot_45passwordIyUVzZ8S9zMeta || {},
    alias: forgot_45passwordIyUVzZ8S9zMeta?.alias || [],
    redirect: forgot_45passwordIyUVzZ8S9zMeta?.redirect || undefined,
    component: () => import("/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/index/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: indexRytedw4eF9Meta?.name ?? "index",
    path: indexRytedw4eF9Meta?.path ?? "",
    meta: indexRytedw4eF9Meta || {},
    alias: indexRytedw4eF9Meta?.alias || [],
    redirect: indexRytedw4eF9Meta?.redirect || undefined,
    component: () => import("/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: login3ERfpisPLkMeta?.name ?? "index-login",
    path: login3ERfpisPLkMeta?.path ?? "login",
    meta: login3ERfpisPLkMeta || {},
    alias: login3ERfpisPLkMeta?.alias || [],
    redirect: login3ERfpisPLkMeta?.redirect || undefined,
    component: () => import("/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/index/login.vue").then(m => m.default || m)
  },
  {
    name: registerdjVRwlqwbBMeta?.name ?? "index-register",
    path: registerdjVRwlqwbBMeta?.path ?? "register",
    meta: registerdjVRwlqwbBMeta || {},
    alias: registerdjVRwlqwbBMeta?.alias || [],
    redirect: registerdjVRwlqwbBMeta?.redirect || undefined,
    component: () => import("/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/index/register.vue").then(m => m.default || m)
  },
  {
    name: verify_45emailObaTddw4LsMeta?.name ?? "index-verify-email",
    path: verify_45emailObaTddw4LsMeta?.path ?? "verify-email",
    meta: verify_45emailObaTddw4LsMeta || {},
    alias: verify_45emailObaTddw4LsMeta?.alias || [],
    redirect: verify_45emailObaTddw4LsMeta?.redirect || undefined,
    component: () => import("/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/index/verify-email.vue").then(m => m.default || m)
  },
  {
    name: verifyZYLD0Hpz1xMeta?.name ?? "index-verify",
    path: verifyZYLD0Hpz1xMeta?.path ?? "verify",
    meta: verifyZYLD0Hpz1xMeta || {},
    alias: verifyZYLD0Hpz1xMeta?.alias || [],
    redirect: verifyZYLD0Hpz1xMeta?.redirect || undefined,
    component: () => import("/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/index/verify.vue").then(m => m.default || m)
  }
],
    name: indexKYgMfNkA0KMeta?.name ?? undefined,
    meta: indexKYgMfNkA0KMeta || {},
    alias: indexKYgMfNkA0KMeta?.alias || [],
    redirect: indexKYgMfNkA0KMeta?.redirect || undefined,
    component: () => import("/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: jobsYNX41RUbPIMeta?.name ?? "jobs",
    path: jobsYNX41RUbPIMeta?.path ?? "/jobs",
    meta: jobsYNX41RUbPIMeta || {},
    alias: jobsYNX41RUbPIMeta?.alias || [],
    redirect: jobsYNX41RUbPIMeta?.redirect || undefined,
    component: () => import("/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/jobs.vue").then(m => m.default || m)
  },
  {
    name: _91orderv2Id_93cu9FUtdLLeMeta?.name ?? "order-orderv2Id",
    path: _91orderv2Id_93cu9FUtdLLeMeta?.path ?? "/order/:orderv2Id()",
    meta: _91orderv2Id_93cu9FUtdLLeMeta || {},
    alias: _91orderv2Id_93cu9FUtdLLeMeta?.alias || [],
    redirect: _91orderv2Id_93cu9FUtdLLeMeta?.redirect || undefined,
    component: () => import("/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/order/[orderv2Id].vue").then(m => m.default || m)
  },
  {
    name: cart4cMe1ncdGwMeta?.name ?? "order-cart",
    path: cart4cMe1ncdGwMeta?.path ?? "/order/cart",
    meta: cart4cMe1ncdGwMeta || {},
    alias: cart4cMe1ncdGwMeta?.alias || [],
    redirect: cart4cMe1ncdGwMeta?.redirect || undefined,
    component: () => import("/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/order/cart.vue").then(m => m.default || m)
  },
  {
    name: checkout2azuJbKbivMeta?.name ?? "order-checkout",
    path: checkout2azuJbKbivMeta?.path ?? "/order/checkout",
    meta: checkout2azuJbKbivMeta || {},
    alias: checkout2azuJbKbivMeta?.alias || [],
    redirect: checkout2azuJbKbivMeta?.redirect || undefined,
    component: () => import("/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/order/checkout.vue").then(m => m.default || m)
  },
  {
    name: index12juo0NYadMeta?.name ?? "order",
    path: index12juo0NYadMeta?.path ?? "/order",
    meta: index12juo0NYadMeta || {},
    alias: index12juo0NYadMeta?.alias || [],
    redirect: index12juo0NYadMeta?.redirect || undefined,
    component: () => import("/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/order/index.vue").then(m => m.default || m)
  },
  {
    name: _91token_93Y6DieYIDwmMeta?.name ?? "password-reset-token",
    path: _91token_93Y6DieYIDwmMeta?.path ?? "/password-reset/:token()",
    meta: _91token_93Y6DieYIDwmMeta || {},
    alias: _91token_93Y6DieYIDwmMeta?.alias || [],
    redirect: _91token_93Y6DieYIDwmMeta?.redirect || undefined,
    component: () => import("/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/password-reset/[token].vue").then(m => m.default || m)
  },
  {
    name: privacypolicyEwGYrEBY3RMeta?.name ?? "privacypolicy",
    path: privacypolicyEwGYrEBY3RMeta?.path ?? "/privacypolicy",
    meta: privacypolicyEwGYrEBY3RMeta || {},
    alias: privacypolicyEwGYrEBY3RMeta?.alias || [],
    redirect: privacypolicyEwGYrEBY3RMeta?.redirect || undefined,
    component: () => import("/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/privacypolicy.vue").then(m => m.default || m)
  },
  {
    name: productssALdo5NBUBMeta?.name ?? "products",
    path: productssALdo5NBUBMeta?.path ?? "/products",
    meta: productssALdo5NBUBMeta || {},
    alias: productssALdo5NBUBMeta?.alias || [],
    redirect: productssALdo5NBUBMeta?.redirect || undefined,
    component: () => import("/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/products.vue").then(m => m.default || m)
  },
  {
    name: shippingandorderpolicyccwLY0Lay9Meta?.name ?? "shippingandorderpolicy",
    path: shippingandorderpolicyccwLY0Lay9Meta?.path ?? "/shippingandorderpolicy",
    meta: shippingandorderpolicyccwLY0Lay9Meta || {},
    alias: shippingandorderpolicyccwLY0Lay9Meta?.alias || [],
    redirect: shippingandorderpolicyccwLY0Lay9Meta?.redirect || undefined,
    component: () => import("/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/shippingandorderpolicy.vue").then(m => m.default || m)
  },
  {
    name: termsofusejIke6rr4JtMeta?.name ?? "termsofuse",
    path: termsofusejIke6rr4JtMeta?.path ?? "/termsofuse",
    meta: termsofusejIke6rr4JtMeta || {},
    alias: termsofusejIke6rr4JtMeta?.alias || [],
    redirect: termsofusejIke6rr4JtMeta?.redirect || undefined,
    component: () => import("/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/termsofuse.vue").then(m => m.default || m)
  },
  {
    name: testPayment51RjN45jgUMeta?.name ?? "testPayment",
    path: testPayment51RjN45jgUMeta?.path ?? "/testPayment",
    meta: testPayment51RjN45jgUMeta || {},
    alias: testPayment51RjN45jgUMeta?.alias || [],
    redirect: testPayment51RjN45jgUMeta?.redirect || undefined,
    component: () => import("/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/testPayment.vue").then(m => m.default || m)
  },
  {
    name: _91id_93aLQDSPJUrLMeta?.name ?? "user-id",
    path: _91id_93aLQDSPJUrLMeta?.path ?? "/user/:id()",
    meta: _91id_93aLQDSPJUrLMeta || {},
    alias: _91id_93aLQDSPJUrLMeta?.alias || [],
    redirect: _91id_93aLQDSPJUrLMeta?.redirect || undefined,
    component: () => import("/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/user/[id].vue").then(m => m.default || m)
  },
  {
    name: exist_45loginL6IqdaCJV2Meta?.name ?? "v2-exist-login",
    path: exist_45loginL6IqdaCJV2Meta?.path ?? "/v2/exist-login",
    meta: exist_45loginL6IqdaCJV2Meta || {},
    alias: exist_45loginL6IqdaCJV2Meta?.alias || [],
    redirect: exist_45loginL6IqdaCJV2Meta?.redirect || undefined,
    component: () => import("/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/v2/exist-login.vue").then(m => m.default || m)
  },
  {
    name: loginbraAuB9i6SMeta?.name ?? "v2-login",
    path: loginbraAuB9i6SMeta?.path ?? "/v2/login",
    meta: loginbraAuB9i6SMeta || {},
    alias: loginbraAuB9i6SMeta?.alias || [],
    redirect: loginbraAuB9i6SMeta?.redirect || undefined,
    component: () => import("/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/v2/login.vue").then(m => m.default || m)
  },
  {
    name: setup_45profileimKmgy7lSxMeta?.name ?? "v2-setup-profile",
    path: setup_45profileimKmgy7lSxMeta?.path ?? "/v2/setup-profile",
    meta: setup_45profileimKmgy7lSxMeta || {},
    alias: setup_45profileimKmgy7lSxMeta?.alias || [],
    redirect: setup_45profileimKmgy7lSxMeta?.redirect || undefined,
    component: () => import("/home/mdp0811/office_work/95dot/95dot-com-frontend/pages/v2/setup-profile.vue").then(m => m.default || m)
  }
]